<template>
  <section class="app-ecommerce-details">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="currProduct === undefined">
      <h4 class="alert-heading">
        Erreur de récupération des données du produit
      </h4>
      <div class="alert-body">
        Aucun produit trouvé avec cette valeur. Vérifier
        <b-link class="alert-link" @click="$router.back()"> le magasin</b-link>
        pour d'autres produits.
      </div>
    </b-alert>

    <!-- Content -->
    <b-card v-if="currProduct" no-body>
      <b-card-body>
        <b-row class="my-2">
          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                :src="currProduct.image"
                :alt="`Image de ${currProduct.uid}`"
                class="product-img"
                fluid
              />
            </div>
          </b-col>

          <!-- Right: Product Details -->
          <b-col cols="12" md="7">
            <div class="d-flex justify-content-start mb-2">
              <b-button
                variant="primary"
                class="btn-icon mr-1"
                size="sm"
                @click="
                  goBack
                "
              >
                <feather-icon icon="ArrowLeftIcon" />
              </b-button>
              <!-- Product Name -->
              <h3>{{ currProduct.name }}</h3>
            </div>

            <!-- Product Brand -->
            <b-card-text class="item-company mb-0">
              <span>par</span>
              <b-link class="company-name">
                {{ currProduct.brand.name }}
              </b-link>
            </b-card-text>

            <!-- Price And Ratings -->
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <!-- <h4 class="item-price mr-1">
                ${{ currProduct.price }}
              </h4>
              <ul class="unstyled-list list-inline pl-1 border-left">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item mr-25"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="18"
                    :class="[{'fill-current': star <= currProduct.rating}, star <= currProduct.rating ? 'text-warning' : 'text-muted']"
                  />
                </li>
              </ul> -->
            </div>

            <!-- Avability -->
            <b-card-text
              >Disponibilité -
              <span
                :class="[
                  isAvailableInStock(currProduct)
                    ? 'text-success'
                    : 'text-danger',
                ]"
                >{{
                  isAvailableInStock(currProduct) ? "En stock" : "Vide"
                }}</span
              ></b-card-text
            >

            <!-- Product Meta [Free shpping, EMI, etc.] -->
            <ul class="product-features list-unstyled">
              <li v-if="!currProduct.is_depreciable">
                <feather-icon class="text-danger" icon="AlertTriangleIcon" />
                <span>Est consommable</span>
              </li>
              <li>
                <feather-icon icon="TagIcon" />
                <span>Disponible : {{ currProduct.details.available }}</span>
              </li>
            </ul>

            <hr />
            <!-- Product Description -->
            <i v-if="!currProduct.description" class="text-muted"
              >Aucune description sur cet article.</i
            >
            <b-card-text v-else>{{ currProduct.description }}</b-card-text>
            <!-- Colors -->
            <!-- <div class="product-color-options">
              <h6>Colors</h6>
              <ul class="list-unstyled mb-0">
                <li
                  v-for="color in currProduct.colorOptions"
                  :key="color"
                  class="d-inline-block"
                  :class="{'selected': selectedColor === color}"
                  @click="selectedColor = color"
                >
                  <div
                    class="color-option"
                    :class="`b-${color}`"
                  >
                    <div
                      class="filloption"
                      :class="`bg-${color}`"
                    />
                  </div>
                </li>
              </ul>
            </div> -->

            <hr />

            <div class="d-flex flex-column flex-sm-row pt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="productDispatch(currProduct)"
                :disabled="!isAvailableInStock(currProduct)"
              >
                <feather-icon
                  :icon="currProduct.is_in_cart ? 'LogInIcon' : 'LogOutIcon'"
                  class="mr-50"
                />
                <span>{{
                  currProduct.is_in_cart ? "Afficher" : "Sortir"
                }}</span>
              </b-button>
              <b-button
                variant="outline-secondary"
                class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="toggleBookingProduct(currProduct)"
                :disabled="!isAvailableInStock(currProduct)"
              >
                <feather-icon
                  :class="{ 'text-danger': currProduct.isInWishList }"
                  :icon="
                    currProduct.isInWishList
                      ? 'PauseCircleIcon'
                      : 'CalendarIcon'
                  "
                  class="mr-50"
                />
                <span
                  >{{ currProduct.is_in_wish_list ? "Attente" : "Réserver" }}</span
                >
              </b-button>
              <!-- <b-dropdown
                variant="outline-secondary"
                no-caret
                toggle-class="btn-icon"
                class="btn-share"
                right
              >
                <template #button-content>
                  <feather-icon icon="Share2Icon" />
                </template>
                <b-dropdown-item
                  v-for="icon in [ 'FacebookIcon', 'TwitterIcon', 'YoutubeIcon', 'InstagramIcon', ]"
                  :key="icon"
                >
                  <feather-icon :icon="icon" />
                </b-dropdown-item>
              </b-dropdown> -->
            </div>
          </b-col>
        </b-row>
      </b-card-body>

      <!-- Static Content -->
       <store-view-stock-item-details-item-features />

      <!-- Static Content -->
      <!-- Slider: Related Products -->
      <!--      <store-view-stock-item-details-related-products />-->
    </b-card>
  </section>
</template>

<script>
import { useRouter } from "@core/utils/utils";
import store from "@/store";
import { ref, computed } from "@vue/composition-api";
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import StoreViewStockItemDetailsItemFeatures from "./StoreViewStockItemDetailsItemFeatures.vue";
// import StoreViewStockItemDetailsRelatedProducts from "./StoreViewStockDetailsRelatedItems.vue";
import { useStoreViewUi } from "../useStoreView";
import router from "@/router";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    // BDropdown,
    // BDropdownItem,
    BAlert,

    // SFC
    StoreViewStockItemDetailsItemFeatures,
    // StoreViewStockItemDetailsRelatedProducts,
  },
  setup() {
    const {
      handleCartActionClick,
      toggleProductInWishlist,
      isAvailableInStock,
    } = useStoreViewUi();

    const currProduct = computed({
      get: () => store.state.storeManagement.product,
      set: (val) => store.commit("storeManagement/SET_CURRENT_PRODUCT", val),
    });

    const goBack = () => {
      router.push({
        name: 'apps-store-details',
        params: { id: currProduct.details.store },
      })
      store.commit("storeManagement/SET_CURRENT_PRODUCT", null)
    };

    const productDispatch = async (product) => {
      await handleCartActionClick(product);
      store.commit("storeManagement/SET_CURRENT_PRODUCT", {
        ...product,
        is_in_cart: true,
      });
    };

    const toggleBookingProduct = async (product) => {
      await toggleProductInWishlist(product);
      store.commit("storeManagement/SET_CURRENT_PRODUCT", {
        ...product,
        is_in_wish_list: !product.is_in_wish_list,
      });
    }

    // Remote Data
    const fetchProduct = () => {
      // Get product slug, stock & store
      const { uid, details } = store.state["stock"].product;

      store
        .dispatch("stock/fetchItem", {
          uid,
          stock: details["stock"],
          store: details["store"],
        })
        .then(({ resource }) => {
          currProduct.value = resource;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            currProduct.value = undefined;
          }
        });
    };

    // UI
    const selectedColor = ref(null);

    fetchProduct();

    return {
      // Fetched Product
      currProduct,

      // UI
      isAvailableInStock,
      selectedColor,
      productDispatch,
      goBack,
      toggleBookingProduct,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
