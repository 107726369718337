import store from "@/store";
import { useRouter } from "@core/utils/utils";

export const useStoreView = () => {
  // eslint-disable-next-line arrow-body-style
  const addProductInWishlist = (productId) => {
    return store.commit("storeManagement/ADD_ITEM_IN_WISH_LIST", { productId });
  };

  // eslint-disable-next-line arrow-body-style
  const removeProductFromWishlist = (productId) => {
    return store.commit("storeManagement/REMOVE_ITEM_FROM_WISH_LIST", {
      productId,
    });
  };

  // eslint-disable-next-line arrow-body-style
  const addProductInCart = (productId) => {
    return store.commit("storeManagement/ADD_ITEM_IN_CART", { productId });
  };

  // eslint-disable-next-line arrow-body-style
  const removeProductFromCart = (productId) => {
    return store.commit("storeManagement/REMOVE_ITEM_FROM_CART", {
      productId
    });
  };

  return {
    addProductInWishlist,
    removeProductFromWishlist,
    addProductInCart,
    removeProductFromCart,
  };
};

export const useStoreViewUi = () => {
  const { router } = useRouter();

  const toggleProductInWishlist = async ({ details }) => {
    const { addProductInWishlist, removeProductFromWishlist } = useStoreView();
    if (details.is_in_wish_list) {
      await removeProductFromWishlist(details.id);
    } else {
      await addProductInWishlist(details.id);
    }
  };

  const handleCartActionClick = async ({ details }) => {
    const { addProductInCart } = useStoreView();

    if (details.is_in_cart) {
      await router.push({
        name: "apps-store-product-checkout",
        params: { id: details.store },
      });
    } else {
      await addProductInCart(details.id);
      store.commit(
        "storeManagement/UPDATE_CART_ITEMS_COUNT",
        store.state["storeManagement"].cartItemsCount + 1
      );
    }
  };

  const handleWishlistCartActionClick = (
    product,
    removeProductFromWishlistUi
  ) => {
    const { addProductInCart, removeProductFromWishlist } = useStoreView();

    if (product.isInCart) {
      router.push({
        name: "apps-store-product-checkout",
        params: { id: product.details.store },
      });
    } else {
      addProductInCart(product.id)
        .then(() => {
          // eslint-disable-next-line no-param-reassign
          product.isInCart = true;
          removeProductFromWishlist(product.id);

          // Update cart items count
          store.commit(
            "storeManagement/UPDATE_CART_ITEMS_COUNT",
            store.state["storeManagement"].cartItemsCount + 1
          );
        })
        .then(() => {
          // eslint-disable-next-line no-param-reassign
          product.isInWishlist = false;
          removeProductFromWishlistUi(product);
        });
    }
  };

  const isAvailableInStock = (product) => {
    if (product.details === undefined) return false;
    return product.details?.available !== 0;
  };

  return {
    toggleProductInWishlist,
    handleCartActionClick,
    handleWishlistCartActionClick,
    isAvailableInStock,
  };
};
